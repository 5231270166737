<template>
  <div class="sheet">
    <table>
      <tbody>
        <tr>
          <td>
            <!-- Start: Logo & Title -->
            <div class="d-flex align-center justify-space-between">
              <div>
                <div class="sheet-logo--container">
                  <img
                    v-if="
                      orderProcessing.site === LIST_MODEL_SITES.GOODSPACE.id
                    "
                    alt="Logo"
                    class="pb-3"
                    src="../assets/images/logo-goodspace.svg"
                    width="120px"
                  />
                  <img
                    v-else
                    alt="Logo"
                    src="../assets/images/logo-thinkpro.svg"
                    width="128.6px"
                  />
                </div>
                <div class="mt-2" style="max-width: 276px">
                  <div class="sheet-font--bold">Chi nhánh</div>
                  <div>
                    {{
                      orderProcessing.branch_nhan &&
                        orderProcessing.branch_nhan.address
                    }}
                  </div>
                </div>
              </div>
              <div>
                <div class="sheet-title sheet-font--bold">PHIẾU XUẤT KHO</div>
                <div class="mt-2">
                  <div class="d-flex align-center justify-space-between">
                    <div>Số hóa đơn</div>
                    <div class="sheet-font--bold">
                      {{ orderProcessing.code }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div>Ngày ghi</div>
                    <div class="sheet-font--bold">
                      {{ orderProcessing.created_at }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div>NVBH</div>
                    <div class="sheet-font--bold">
                      {{
                        orderProcessing.created_by_user &&
                          orderProcessing.created_by_user.name
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End: Logo & Title -->
            <div class="sheet-divider my-4"></div>
            <!-- Start: Customer info -->
            <div
              v-if="orderProcessing.customer"
              class="d-flex justify-space-between"
            >
              <div class="pr-6">
                <div class="sheet-font--bold">THÔNG TIN KHÁCH HÀNG</div>
                <div>
                  <span class="sheet-font--bold">Họ & Tên: </span>
                  <span>{{ orderProcessing.customer.name }}</span>
                </div>
                <div>
                  <span class="sheet-font--bold">SĐT: </span>
                  <span v-if="orderProcessing.customer.phone">
                    {{
                      orderProcessing.customer.phone | VMask("### ### ####")
                    }}</span
                  >
                </div>
                <div v-if="orderProcessing.customer.email">
                  <span class="sheet-font--bold">Email: </span>
                  <span>{{ orderProcessing.customer.email }}</span>
                </div>
                <div v-if="!!getAddressText(orderProcessing.customer)">
                  <span class="sheet-font--bold">Địa chỉ: </span>
                  <span>{{ getAddressText(orderProcessing.customer) }}</span>
                </div>
              </div>
              <div
                class="sheet-signature sheet-text--center"
                style="max-width: 276px"
              >
                <div class="px-3" style="max-width: 235px">
                  (Chữ kỹ xác nhận đã được NVBH phổ biến quy định bảo hành & đổi
                  trả)
                </div>
              </div>
            </div>
            <!-- End: Customer info -->
            <!-- Start: Products -->
            <div class="sheet-products mt-5">
              <!-- Start: Products list -->
              <table>
                <thead>
                  <tr>
                    <th class="text-left">SẢN PHẨM</th>
                    <th>ĐƠN GIÁ</th>
                    <th>CHIẾT KHẤU</th>
                    <th>SỐ LƯỢNG</th>
                    <th>THÀNH TIỀN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in orderProcessing.details" :key="item.id">
                    <td>
                      <div class="sheet-font--bold">
                        {{ item.option && item.option.product_name }}
                      </div>
                      <div>
                        Serial:
                        <span
                          v-for="(serial, index) in item.serials"
                          :key="serial.id"
                        >
                          {{ serial.serial_number }}

                          <template v-if="index < item.serials.length - 1"
                            >,</template
                          >
                        </span>
                      </div>
                    </td>

                    <td class="sheet-text--center">
                      {{ item.unit_price | formatCurrency("VND") }}
                    </td>

                    <td class="sheet-text--center">
                      {{ item.rebate_amount | formatCurrency("VND") }}
                    </td>

                    <td class="sheet-text--center">
                      {{ item.serials.length }}
                    </td>
                    <td class="sheet-text--right">
                      {{
                        ((item.unit_price - item.rebate_amount) *
                          item.serials.length)
                          | formatCurrency("VND")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- End: Products  list-->

              <v-divider></v-divider>
              <!-- Start: Total -->
              <div class="d-flex flex-column align-end">
                <div
                  class="d-flex justify-space-between sheet-font--bold sheet-total text-large"
                >
                  <div>Khách cần trả</div>
                  <div>
                    {{ goodsTotal | formatCurrency("VND") }}
                  </div>
                </div>
              </div>
              <!-- End: Total -->
            </div>
            <!-- End: Products -->
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td>
            <!--place holder for the fixed-position footer-->
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>

    <div class="sheet-insurance">
      <div class="sheet-divider my-3"></div>
      <!-- Start: More info -->
      <div class="sheet-info">
        <!-- Start: Warranty -->
        <div>
          <div class="sheet-font--bold">QUY ĐỊNH BẢO HÀNH</div>
          <ul>
            <li class="sheet-info--text-content">
              <div class="text-back">
                Máy sử dụng không đúng như mong đợi của khách hàng sẽ được hỗ
                trợ đổi sang model khác, tuy nhiên sẽ mất phí đổi/hoàn máy theo
                chính sách đổi/hoàn máy tại cửa hàng.
              </div>
            </li>
            <li class="sheet-info--text-content">
              <div class="text-back">
                <div>Những vấn đề sau sẽ KHÔNG nằm trong phạm vi bảo hành:</div>
                <div>
                  <div class="d-flex">
                    <div class="col-auto py-0 pl-0 pr-2">
                      <div>
                        1. Sản phẩm bị lỗi hình thức, biến dạng, mờ, mòn chữ,
                        v.v
                      </div>
                      <div>
                        2. Sản phẩm đã hết thời hạn bảo hành tại
                        {{ nameCompany }}.
                      </div>
                    </div>
                    <div class="col-auto py-0 pl-0 pr-1">
                      <div>3. Khách hàng đã tự ý tháo/dỡ sản phẩm.</div>
                      <div>
                        4. Sản phẩm không có tem của {{ nameCompany }}/tem bị
                        rách.
                      </div>
                    </div>
                    <div class="col sheet-text--right pa-0">
                      <div>5. Màn hình sáng không đều (Đặc tính riêng</div>
                      <div>của một số loại màn hình, không phải lỗi).</div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- End: Warranty -->
        <!-- Start: Refund and return -->
        <div class="mt-3">
          <div class="sheet-font--bold">QUY ĐỊNH ĐỔI TRẢ</div>
          <ul>
            <li class="sheet-info--text-content">
              <div class="text-back">
                Sản phẩm đổi trả phải giữ nguyên 100% ngoại hình ban đầu, gồm vỏ
                hộp, giấy tờ liên quan và đủ điều kiện bảo hành của hãng.
              </div>
            </li>
            <li class="sheet-info--text-content">
              <div class="text-back">
                Thân máy và màn hình máy không bị trầy xước (áp dụng cho Laptop,
                Màn hình máy tính & Máy tính để bàn).
              </div>
            </li>
          </ul>
        </div>
        <!-- End: Refund and return -->
        <!-- Start: Other -->
        <div class="mt-3 d-flex">
          <div class="col-8 pa-0">
            <div class="sheet-font--bold">SẢN PHẨM HỎNG GÌ ĐỔI NẤY</div>
            <ul>
              <li class="sheet-info--text-content">
                <div class="text-back">
                  Đối với sản phẩm chính: Đổi 1-1 cùng model, cấu hình, màu sắc,
                  v.v (chi tiết sẽ được nhân viên hướng dẫn theo trường hợp).
                </div>
              </li>
              <li class="sheet-info--text-content">
                <div class="text-back">
                  Đối với phụ kiện: Đổi phụ kiện có cùng công năng mà
                  {{ nameCompany }} đang kinh doanh (trường hợp không có phụ
                  kiện tương đương hoặc khách hàng không đồng ý,
                  {{ nameCompany }} sẽ áp dụng bảo hành hãng).
                </div>
              </li>
            </ul>
          </div>
          <div class="col-4 pa-0">
            <div class="d-flex mx-1">
              <div class="mx-4">
                <div class="sheet-font--bold">PHẢN HỒI, GÓP Ý, KHIẾU NẠI</div>
                <div class="my-2 sheet-qr">
                  QUÉT QR CODE
                  <span>
                    <v-icon size="18px">mdi-arrow-right</v-icon>
                  </span>
                </div>
              </div>
              <img alt="feedback qr-code" src="../assets/images/qr-code.svg" />
            </div>
          </div>
        </div>
        <!-- End: Other -->
      </div>
      <!-- End: More info -->
    </div>

    <div class="page-footer">
      <div class="sheet-divider my-3"></div>
      <!-- Start: Contacts -->
      <div class="d-flex sheet-contact">
        <div class="col sheet-font--bold pa-0">LIÊN HỆ VỚI CHÚNG TÔI</div>
        <div class="col pa-0"><strong>Hotline:</strong> 1900 633 579</div>
        <div class="col sheet-text--center pa-0">
          <strong>Website:</strong>
          {{ removeHttps(LIST_MODEL_SITES[orderProcessing.site].url) }}
        </div>
        <div class="col sheet-text--right pa-0">
          <strong>Fanpage:</strong>
          fb.com/{{ removeHttps(LIST_MODEL_SITES[orderProcessing.site].url) }}
        </div>
      </div>
      <!-- End: Contacts -->
      <div class="sheet-divider my-3"></div>
      <!-- Start: Stores -->
      <div class="d-flex sheet-stores">
        <div class="col-3 sheet-font--bold pa-0">HỆ THỐNG CỬA HÀNG</div>
        <div class="col-4 pa-0">
          <div class="d-flex">
            <div class="sheet-font--bold">Hà Nội:</div>
            <ul>
              <li><span class="text-back">53 Thái Hà, Q. Đống Đa</span></li>
            </ul>
          </div>
        </div>
        <div class="col-5 pa-0">
          <div class="d-flex justify-end">
            <div class="sheet-font--bold">TP.HCM:</div>
            <ul>
              <li>
                <div class="text-back">
                  5-7 Nguyễn Huy Tưởng, P. 6, Q. Bình Thạnh
                </div>
              </li>
              <li>
                <div class="text-back">95 Trần Thiện Chánh, P. 12, Q. 10</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End: Stores -->
    </div>
  </div>
</template>

<script>
import { formatDateTime } from "@/core/utils";
import { getAddressText } from "@/core/composables";
import { LIST_MODEL_SITES } from "@/core/constant";

export default {
  data() {
    return {
      LIST_MODEL_SITES
    };
  },

  computed: {
    billDiscount() {
      return this.orderProcessing.return_phitrahang
        ? this.orderProcessing.return_goods_value -
            this.orderProcessing.return_phitrahang
        : this.orderProcessing.return_goods_value;
    },

    nameCompany() {
      if (this.orderProcessing.site === LIST_MODEL_SITES.GOODSPACE.id) {
        return LIST_MODEL_SITES.GOODSPACE.text;
      } else return LIST_MODEL_SITES.THINKPRO.text;
    },

    orderProcessing() {
      return this.$store.getters["ORDER/orderProcessing"];
    },

    saleReceiptProcessingThuTotal() {
      return this.$store.getters["SALE_RECEIPT/saleReceiptProcessingThuTotal"];
    },

    goodsTotal() {
      return (
        this.orderProcessing.details &&
        this.orderProcessing.details.reduce((total, item) => {
          const giftTotal = item.gifts.reduce(
            (giftTotal, gift) =>
              giftTotal +
              gift.serials.length * (gift.unit_price - gift.rebate_amount),
            0
          );

          return (
            total +
            item.serials.length * (item.unit_price - item.rebate_amount) +
            giftTotal
          );
        }, 0)
      );
    }
  },

  async mounted() {
    const route = this.$route;

    await this.$store.dispatch(
      "ORDER/getOrderProcessingById",
      route.params.handleId
    );

    setTimeout(() => {
      this.$nextTick(() => {
        this.printPage();
      });
    }, 1000);
  },

  methods: {
    formatDateTime,
    getAddressText() {
      return getAddressText(this.orderProcessing.customer);
    },

    printPage() {
      const sheet = document.getElementsByClassName("sheet");
      const index = Math.ceil((sheet[0].offsetHeight - 90.8) / 1123); //90.8 is top margin + bottom margin, 1123 is the height of A4(px)
      const height = index * 297 - index * 2 * 12; //297 is the height of A4(mm), 12 is margin top, margin bottton of page
      sheet[0].style.height = `${height}mm`;
      this.$nextTick(() => {
        document.title = this.orderProcessing.code;
        window.print();
      });
    },

    removeHttps(url) {
      return url.replace("https://", "");
    }
  }
};
</script>

<style lang="scss" scoped>
.sheet-sale-receipt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
